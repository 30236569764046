<template>
  <div
    class="py-4 text-sm text-fr-secondary bg-white breadcrumb-nav theme-text-base font-figtree"
    v-if="breadcrumbs.breadcrumbsList?.length"
  >
    <div class="px-6 mx-auto xl:px-0 max-w-screen-xl">
      <ul class="flex flex-wrap">
        <li class="ml-3">
          <NuxtLink to="/">
            <HomeIcon class="w-5 h-5 text-[#9ca3af]" alt="Home" />
          </NuxtLink>
        </li>
        <li
          v-for="(crumb, index) in breadcrumbs.breadcrumbsList"
          class="before:content-['/'] before:text-[#d1d5db]"
        >
          <a
            v-if="crumb.refreshPage"
            :href="getLink(crumb)"
            :key="`a-${index}`"
            class="whitespace-nowrap uppercase"
          >
            {{ crumb.title }}
          </a>
          <NuxtLink
            v-else
            :to="getLink(crumb)"
            :key="`nuxt-link-${index}`"
            class="whitespace-nowrap uppercase text-[13px] font-medium tracking-wider"
          >
            {{ crumb.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
li {
  &::before {
    @apply mx-3;
  }
}
</style>

<script setup>
import { HomeIcon } from "@heroicons/vue/solid/index.js";
const breadcrumbs = useBreadcrumbs();
const route = useRoute();
watch(
  () => route.query,
  () => refreshNuxtData("route")
);

const getLink = (crumb) => {
  if (typeof crumb.link === 'string') {
    return crumb.link;
  } else if (Array.isArray(crumb.link)) {
    return crumb.link.filter(Boolean).join('/');
  }
  return '';
};
</script>
